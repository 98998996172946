@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-display: swap;
  font-weight: 400;
  src: local("Inter Regular"),
       url("../fonts/Inter-Regular.woff2") format("woff2"),
       url("../fonts/Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-display: swap;
  font-weight: 400;
  src: local("Inter Italic"),
       url("../fonts/Inter-Italic.woff2") format("woff2"),
       url("../fonts/Inter-Italic.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-display: swap;
  font-weight: 600;
  src: local("Inter Medium"),
       url("../fonts/Inter-Medium.woff2") format("woff2"),
       url("../fonts/Inter-Medium.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-display: swap;
  font-weight: 600;
  src: local("Inter Medium Italic"),
       url("../fonts/Inter-MediumItalic.woff2") format("woff2"),
       url("../fonts/Inter-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-display: swap;
  font-weight: 800;
  src: local("Inter Bold"),
       url("../fonts/Inter-Bold.woff2") format("woff2"),
       url("../fonts/Inter-Bold.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-display: swap;
  font-weight: 800;
  src: local("Inter Bold Italic"),
       url("../fonts/Inter-BoldItalic.woff2") format("woff2"),
       url("../fonts/Inter-BoldItalic.woff") format("woff");
}
